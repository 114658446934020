import { ActionTypes } from '../actionTypes/action-Types'

let initialState = {
  baseUrl: 'https://evoltion.in/api/',
  sidebarShow: true,
  sidebarUnfoldable: false,
  token: localStorage.getItem('token'),
  name: localStorage.getItem('name'),
}

export const userReducer = (state = initialState, { type,payload }) => {
  switch (type) {
    case 'set':
      return { ...state, ...payload }

    case ActionTypes.LOGIN:
      return { ...state, ...payload }

    case ActionTypes.LOGOUT:
      return { ...state, payload }

    default:
      return state
  }
}
